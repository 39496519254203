import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import TypeAnimation from "react-type-animation";
import "./Banner.css";

const Banner = () => {
  return (
    <div
      data-aos="fade-up"
      data-aos-duration="1000"
      style={{ boxShadow: "-1px 1px 5px black" }}
      className="pt-5 mb-5 pc-horizontal mobile-horizontal"
    >
      <Container data-aos="fade-up" data-aos-duration="1500">
        <Row>
          <Col
            data-aos="fade-up"
            data-aos-duration="2000"
            xs={12}
            sm={6}
            md={4}
            className="mobile-horizontal-remove"
          >
            <div className="pro-pic w-100 pt-0 pb-md-5 pb-3 px-4">
              <img
                className="w-100 shadow"
                src="https://i.ibb.co/Sy7wJsp/person1-1-1-1-1.png"
                alt=""
              />
            </div>
          </Col>
          <Col
            xs={12}
            sm={6}
            md={8}
            className="ps-md-5 pt-3 mt-5 pb-3 text-light"
          >
            {/* <h1>Hassan Zahar Rifat<span>_</span></h1> */}
            <div style={{ minHeight: "45px", marginBottom: "0" }}>
              <TypeAnimation
                cursor={false}
                sequence={[`I'm`, 1400, "Hassan Zahar Rifat"]}
                wrapper="h1"
                repeat={1}
                className="type"
              />
            </div>
            <h5 className="mt-0 mb-2">{`<Full-Stack Developer />`}</h5>
            <div className="mail-link mt-4">
              <a
                target="_blank"
                className="d-flex"
                rel="noreferrer"
                href="mailto:hz.rifat@gmail.com"
              >
                <div className="mx-2">
                  {/* <p>Email:</p> */}
                  <i className="fa-solid fa-envelope fs-4"></i>
                  {/* <p>Phone:</p> */}
                  {/* <p>Address:</p> */}
                </div>
                <div className="mx-2">
                  <TypeAnimation
                    cursor={false}
                    sequence={["...", 1320, "hz.rifat@gmail.com"]}
                    wrapper="p"
                    repeat={1}
                  />
                  {/* <TypeAnimation
                  cursor={false}
                  sequence={["...", 1320, "+880 1743386488"]}
                  wrapper="p"
                  repeat={1}
                />
                <TypeAnimation
                  cursor={false}
                  sequence={["...", 1250, "Rajshahi, Bangladesh"]}
                  wrapper="p"
                  repeat={1}
                /> */}
                  {/* <p>zsb</p>
                                <p>zfb</p>
                                <p>gbzxb</p> */}
                </div>
              </a>
            </div>
            <div className="fs-3 mt-md-5 mt-4 pt-md-0 pt-1 pb-md-0 pb-2 profile-links">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://github.com/h-zahar"
              >
                <i className="fab fa-github mx-2" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/h-zahar/"
              >
                <i className="fab fa-linkedin mx-2" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/hzaharr"
              >
                <i class="fa-brands fa-twitter mx-2"></i>
              </a>
            </div>
            {/* <a target="_blank" href="./resume.pdf">
              <button className="btn-resume mt-md-5 mt-4 mb-4 rounded py-2 px-4 border-0">
                Download Resume
              </button>
            </a> */}
            <a href="#contact">
              <button className="btn-resume mt-md-4 mt-3 mb-4 rounded py-2 px-4 border-0">
                <b>Contact Me!</b>
              </button>
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Banner;
