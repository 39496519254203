import React from 'react';

const DescList = (props) => {
    return (
        <li className="mb-3">
            {props.elem}
        </li>
    )
}

export default DescList;