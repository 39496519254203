import React from "react";
import "./Header.css";

const Header = () => {
  return (
    <header
      style={{
        // position: "sticky",
        top: "0",
        zIndex: "4",
        boxShadow: "1px 1px 10px rgba(0, 0, 0, 0.4)",
      }}
      className="bg-header"
    >
      <div>
        <nav
          style={{ color: "white" }}
          className="d-flex justify-content-center"
        >
          <ul className="nav-style d-flex my-auto">
            <li style={{ marginLeft: "-23px" }}>
              <a href="#">Home</a>
            </li>
            <li>
              <a href="#projects">Projects</a>
            </li>
            <li>
              <a href="#blogs">Blogs</a>
            </li>
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#contact">Contact</a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
