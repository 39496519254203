import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import TopProject from '../TopProject/TopProject';

const TopProjects = () => {
    const [projects, setProjects] = useState([]);
    useEffect(() => {
        fetch('./data-json/top.json')
        .then(res => res.json())
        .then(data => setProjects(data));
    }, [])
    return (
        <Container id="projects" className="mx-auto pt-5 pb-5">
            <h2 data-aos="fade-up" data-aos-offset="10" className="pt-5 mb-5 pb-2 text-center">Top Projects</h2>
            <Container style={{maxWidth: '1200px'}}>
                <Row className="d-flex justify-content-center gy-md-5 gy-4">
                    {
                        projects.map(project => {
                            return  <TopProject key={project.id} project={project}/>
                        })
                    }
                </Row>
            </Container>
        </Container>
    )
}

export default TopProjects;