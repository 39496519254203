import React from 'react';
import Footer from '../../Shared/Footer/Footer';
import About from '../About/About';
import Banner from '../Banner/Banner';
import Contact from '../Contact/Contact';
import TopBlogs from '../TopBlogs/TopBlogs/TopBlogs';
import TopProjects from '../TopProjects/TopProjects/TopProjects';

const HomeComponents = () => {
    return (
        <div id="#">
            <Banner />
            <TopProjects />
            <TopBlogs />
            <About />
            <Contact />
            <Footer />
        </div>
    )
}

export default HomeComponents;