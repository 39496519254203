import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
// import TypeAnimation from "react-type-animation";
import TopBlog from "../TopBlog/TopBlog";
import "./TopBlogs.css";

const TopBlogs = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const addedBlogs = [];
    const selectedBlogs = [
      "https://dev.to/hzahar/can-i-show-pie-charts-on-my-website-introducing-recharts-55p",
      "https://dev.to/hzahar/wanna-create-your-own-version-of-messenger-learn-setting-up-socketio-3lkj",
      "https://dev.to/hzahar/you-must-know-the-answers-to-the-7-most-basic-questions-about-react-24j2",
    ];

    fetch("https://dev.to/api/articles?username=hzahar")
      .then((res) => res.json())
      .then((data) => {
        selectedBlogs.forEach((blog) => {
          const temp = data.find((single) => single.canonical_url === blog);
          if (temp !== undefined) {
            addedBlogs.push(temp);
          }
        });
        setBlogs(addedBlogs);
      })
      .catch((error) => console.log(error));
  }, []);
  return (
    <div id="blogs" className="mt-5 pt-5">
      <h2
        data-aos="fade-right"
        data-aos-once="false"
        className="text-center mt-3 mb-5"
      >
        Top Blogs
      </h2>
      <Container>
        <Row className="d-flex justify-content-center pb-4">
          {blogs?.length > 0 &&
            blogs.map((blog, index) => {
              return <TopBlog key={index} blog={blog} />;
            })}
        </Row>

        <div
          style={{
            height: "200px",
            border: "1px solid black",
            borderTop: "none",
            borderBottom: "none",
          }}
          className="mt-5"
        >
          <div
            data-aos="fade-up"
            data-aos-duration="2000"
            data-aos-offset="300"
            data-aos-once="false"
          >
            <a target="_blank" rel="noreferrer" href="https://dev.to/hzahar">
              <button className="blog-prof-btn">DEV Profile</button>
            </a>
          </div>
          <div className="w-100 d-flex justify-content-between align-items-center mt-5">
            <div className="snow-up"></div>
            {/* <h4 className="text-center w-100">Coming Soon...</h4> */}
            {/* <TypeAnimation
                            className="switch-color text-center w-100"
                            cursor={true}
                            sequence={['', 200, 'Coming More...', 1500, 'Stay Tuned!...', 1000]}
                            wrapper="h4"
                            repeat={Infinity}
                        /> */}
            <div className="w-100 responsive-h4">
              <h4 className="text-center switch-color">
                Coming More<span className="ms-1 rot-dot">...</span>
              </h4>
            </div>
            <div className="snow-up"></div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TopBlogs;
