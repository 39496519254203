import React from 'react';
import { Col } from 'react-bootstrap';
import './TopBlog.css';

const TopBlog = ({ blog }) => {
    // console.log(blog);
    return (
        <Col data-aos="fade-up" data-aos-duration="1500"
        data-aos-offset="100"
        data-aos-once="false" className="blog-hov" xs={12} md={4}>
            {
                blog?.id &&
                <>
                    <a className="blog-href" target="_blank" href={blog.canonical_url}>
                        <div className='d-flex justify-content-center'>
                            <button className="react-place react-style px-2 py-1 rounded-circle"><small><em><i className="me-1 mt-2 text-danger fa-solid fa-heart"></i>{blog.public_reactions_count}</em></small></button>
                        </div>
                        <img className='w-100 shadow' src={blog.social_image} alt="" />
                    </a>
                </>
            }
        </Col>
    )
}

export default TopBlog;