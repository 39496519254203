import React from 'react';
import { Container } from 'react-bootstrap';

const Footer = () => {
    return (
        <div style={{background: 'black', margin: '0', minHeight: '50px', fontFamily: `'Baloo Bhaijaan 2', cursive`}} className="text-secondary">
            <Container className="d-flex justify-content-center text-center">
                <div>
                    <p className="pt-4 mt-2 mb-3">Copyright &copy; Hassan Zahar Rifat</p>
                    <p className="mb-3"><small>[ 	&nbsp;This website is created by the author with React.js library and deployed on Firebase. If you do have any queries, you're cordially <a style={{color: 'unset'}} href="/#contact">welcomed</a> 	&nbsp;:v 	&nbsp;]</small></p>
                </div>
            </Container>
        </div>
    )
}

export default Footer;