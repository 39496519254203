import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import firebaseInitialization from './firebase/firebase.init';
// import ReactGA from 'react-ga';
import './App.css';
import Home from './pages/Home/Home/Home';
import DetailedProject from './pages/DynamicRoute/DetailedProject/DetailedProject';

// firebaseInitialization();
// ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID);
// ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path='/'>
            <Home />
          </Route>
          <Route path='/details/:name'>
            <DetailedProject />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
