import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Footer from '../../Shared/Footer/Footer';
import LongDescAddList from './LongDescAddList';
import LongDescList from './LongDescList';
import OtherIDList from './OtherIDsList';
import './DetailedProject.css';

const DetailedProject = () => {
    const { name } = useParams();
    const [project, setProject] = useState({});
    const [otherIDs, setOtherIDs] = useState([]);

    useEffect(() => {
        fetch('/data-json/details.json')
        .then(res => res.json())
        .then(data => {
            setProject(data.find(elem => elem?.title.toLowerCase().split(' ').join('').trim() === name));
            setOtherIDs(data.filter(elem => elem?.title.toLowerCase().split(' ').join('').trim() !== name));
            // console.log(otherIDs);
        });
    }, [name, otherIDs]);

    const handleSwitch = (e) => {
        document.getElementById('wrap-content').classList.toggle('content-toggle');

        document.getElementById('wrap-btn').classList.toggle('btn-toggle');

        e.target.classList.toggle('btn-toggle');

        document.getElementById('wrap-content').classList.toggle('dark-theme');

        document.getElementById('wrap-btn').classList.toggle('dark-btn');

        e.target.classList.toggle('switch-dark-btn');
        e.target.classList.toggle('switch-light-btn');
    };

    return (
        <div id="wrap-content" className="dark-theme" style={{fontFamily: 'monospace'}}>
            <div className="mb-5 mx-4 pt-4 d-flex justify-content-between"><Link exact to='/'>Back</Link><button className="switch-dark-btn" onClick={handleSwitch}>Switch Theme</button></div>
                <Container style={{minHeight: '70vh'}}>
                    <Row>
                        <Col lg={12} xl={6}>
                            <h1>{project?.title}</h1>
                            <div>
                                {
                                    project?.live_site &&
                                    <a target="_black" href={project.live_site}>Live</a>
                                }

                                <p>{project?.long_desc_intro}</p>

                                {
                                    project?.server_side &&
                                    <div className="mb-4"><a target="_blank" href={project.server_side}>Backend Code</a></div>
                                }

                                {
                                    <div>
                                        {
                                            (project?.long_desc_main) &&
                                            (project?.long_desc_main[0]?.length === 2) ?
                                            <h4>Features</h4> :
                                            <h4>Basic Info</h4>
                                        }
                                        {
                                            project?.long_desc_main?.map(pairList => {
                                                return <LongDescList pairList={pairList} />
                                            })
                                        }
                                    </div>
                                }

                                {
                                    project?.long_desc_additional_arr ?
                                    <div>
                                        <h4>Tools</h4>
                                        {
                                            project.long_desc_additional_arr.map(node => {
                                                return <LongDescAddList node={node}/>
                                            })
                                        }
                                    </div> :
                                    <div className="mt-4">{project.long_desc_additional}</div>
                                }
                            </div>
                        </Col>

                        <Col lg={12} xl={6}>
                            <div style={{height: '100%'}} className="d-flex justify-content-center mt-5 pt-5">
                                <div className="fixed-fix">
                                    <div className="d-flex"><i className="fab fa-github fs-4 me-3"></i><a href={project?.client_side}>{'See ${this} Project'}</a></div>
                                    <p className="mt-5"><strong>Wanna Switch to Other Projects?</strong></p>
                                    <div id="wrap-btn" className="dark-btn">
                                        {
                                            otherIDs?.map(otherID => {
                                                return <OtherIDList otherID={otherID}/>
                                            })
                                        }
                                    </div>
                                    {/* <div><Link to='/details/2'>Davasko</Link></div>
                                    <div><Link to='/details/3'>Davasko</Link></div>
                                    <div><Link to='/details/4'>Davasko</Link></div>
                                    <div><Link to='/details/5'>Davasko</Link></div> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="under-message mb-5">
                    <p className="text-center"><strong>[ This page is under development ]</strong></p>
                </div>
            <Footer />
        </div>
    )
}

export default DetailedProject;