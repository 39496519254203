import React, { useEffect, useState } from 'react';
import { Spinner, Button } from 'react-bootstrap';
import Header from '../../Shared/Header/Header';
import HomeComponents from './HomeComponents';


const Home = () => {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1200)
    }, []);
  
    if (isLoading) {
      return (
      <Button style={{width: '100vw', height:'100vh'}} className="fs-1" variant="light" disabled>
      <Spinner
        as="span"
        animation="grow"
        size="lg"
        role="status"
        aria-hidden="true"
      />
      <span className="mx-2">Loading...</span>
      </Button>
      )
    }
    return (
        <>
            {
                !isLoading &&
                <Header />
            }

            {
                !isLoading &&
                <HomeComponents />
            }
        </>
    )
}

export default Home;