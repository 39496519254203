import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

const OtherIDList = (props) => {
    const { otherID } = props;
    const history = useHistory();
    const goTo = () => {
        history.push(`/details/${otherID.title.toLowerCase().split(' ').join('').trim()}`);
        // window.location.reload();
    };
    return (
        <>
            <button onClick={goTo}>{otherID?.title}</button>
        </>
    )
}

export default OtherIDList;