import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import './Contact.css';

emailjs.init(process.env.REACT_APP_EMAILJS_USER_ID);
const Contact = () => {
    const form = useRef();
    const [message, setMessage] = useState('');
    const [messageColor, setMessageColor] = useState('unset');

    const mouseEnter = (e) => {
        // document.getElementById('l-arrow').classList.remove('left-arrow');
        // document.getElementById('r-arrow').classList.remove('right-arrow');
        // document.querySelector('.form-grow-anim').style.animation = 'none';
    }

    const mouseLeave = (e) => {
        // document.getElementById('l-arrow').classList.add('left-arrow');
        // document.getElementById('r-arrow').classList.add('right-arrow');
        // document.querySelector('.ki').style.animationName = 'haha';
        // document.querySelector('.form-grow-anim').style.animation = '2s infinite alternate';
        // document.querySelector('.form-grow-anim').style.animationName = 'anim-form';
    }

    const sendEmail = (e) => {
        e.preventDefault();
        e.target.style.pointerEvents = 'none';
        e.target.style.opacity = '0.5';

        emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, form.current, process.env.REACT_APP_EMAILJS_USER_ID)
          .then((result) => {
              console.log(result.text);
              e.target.reset();
              e.target.style.pointerEvents = 'initial';
              e.target.style.opacity = '1';
              
              setMessageColor('text-success');
              setMessage('Message sent successfully');
              setTimeout(() => {
                  setMessageColor('unset');
                  setMessage('');
              }, 5000);
          }, (error) => {
              console.log(error.text);
              
              e.target.style.pointerEvents = 'initial';
              e.target.style.opacity = '1';

              setMessageColor('text-danger');
              setMessage('Something went wrong!');
              setTimeout(() => {
                  setMessageColor('unset');
                  setMessage('');
              }, 5000);
          });
      };
    return (
        <div id="contact" style={{marginTop: '150px', marginBottom: '120px'}}>
            <div style={{overflow: 'hidden'}} className="d-flex justify-content-center">
            <h2 data-aos="fade-left" data-aos-offset="450" className="d-inline-block text-center pt-3 mb-0 mb-5 pb-2"><i id="r-arrow" className="me-4 right-arrow fa-solid fa-arrow-down-long"></i><span className="grow-anim d-inline-block">Contact Me</span><i id="l-arrow" className="ms-4 left-arrow fa-solid fa-arrow-down-long"></i></h2>
            </div>
            <div className="d-flex justify-content-center mx-2 mx-sm-3">
                <div data-aos="flip-left" data-aos-offset="550" onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} style={{width: '620px', background: 'rgba(255, 255, 255, 1)'}} className="shadow px-4 py-5 rounded bg-dark text-light">
                    <form className="d-flex flex-column mx-0 mx-md-4 px-md-4 px-2" ref={form} onSubmit={sendEmail}>
                        <h3 className="text-center my-md-3 my-2">Leave your message :D</h3><hr />
                        <label className="mb-2"><strong>Name</strong></label>
                        <input style={{border: '1px solid rgba(0, 0, 0, 0.5)'}} className="px-2 py-1 px-md-3 py-md-2 rounded" type="text" placeholder="Enter your name.." name="name" required />
                        <label className="mt-4 mb-2"><strong>Email</strong></label>
                        <input style={{border: '1px solid rgba(0, 0, 0, 0.5)'}} className="px-2 py-1 px-md-3 py-md-2 rounded" type="email" placeholder="Enter your email.." name="email" required />
                        <p className={`mb-3 pt-4 ${messageColor}`}><span style={{visibility: 'hidden'}}>.</span>{message}</p>
                        <label className="mb-2"><strong>Message</strong></label>
                        <textarea style={{border: '1px solid rgba(0, 0, 0, 0.5)', resize: 'none'}} className="px-2 py-1 px-md-3 py-md-2 rounded" placeholder="Drop your message.." name="message" rows="5" required />
                        <input className="mt-md-5 mt-4 py-md-3 py-2 send-btn rounded" type="submit" value="Send" />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Contact;