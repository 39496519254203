import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./About.css";

const About = () => {
  return (
    <div id="about" style={{ marginTop: "50px", paddingTop: "100px" }}>
      <Container
        data-aos="fade-down"
        data-aos-offset="300"
        className="pt-5 mb-5 pb-5 shadow rounded"
      >
        {/* <h2 className="text-center mt-5 pt-5 mb-5 pb-4">About Me</h2> */}
        <Row className="d-flex align-items-center">
          <Col className="px-1" xs={12} md={4}>
            <div
              style={{ marginTop: "-40px", overflow: "hidden" }}
              className="w-100"
            >
              <img
                data-aos="fade-left"
                data-aos-offset="550"
                data-aos-once="false"
                className="w-100 rounded"
                src="https://i.ibb.co/y5RctbT/profile.png"
                alt=""
              />
            </div>
          </Col>

          <Col xs={12} md={8}>
            <div style={{ overflow: "hidden" }}>
              <div
                data-aos="fade-right"
                data-aos-offset="450"
                data-aos-once="false"
                className="shadow rounded"
              >
                <h3 className="text-center py-4">
                  <span className="message">About </span> Me
                </h3>
                <div className="px-3 pb-3">
                  <p>
                    Hi! I'm a Full-Stack Developer who loves to build software
                    on the web. I've built several crucial features to
                    facilitate HR and management-related workflows that are used
                    actively by 250+ employees and employers of a reputed
                    company. Besides, I've built some mid-sized full-stack
                    products. Working hard to maintain my learning and
                    implementation cycle. I've good intention to contribute some
                    good projects and work with other developers.
                  </p>
                  <br />

                  <p>
                    Okay, another necessary info... I'm a tea freak, and also
                    coffee :P
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default About;
