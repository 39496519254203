import React from 'react';

const LongDescList = (props) => {
    const { pairList } = props;
    return (
        <div>
            {
                (pairList.length === 2) ?
                <div className="mb-4">
                    {pairList[0]}<br />{pairList[1]}
                </div> :
                <div className="mb-2">
                    {pairList}
                </div>
            }
        </div>
    )
}

export default LongDescList;