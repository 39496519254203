import React from 'react';import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DescList from './DescList';
import './TopProject.css';

const TopProject = (props) => {
    const { id, img_i, title, live_site, client_side, server_side, short_desc } = props.project;
    const gameProject = 6;

    return (
        <Col data-aos="fade-up" data-aos-duration="1500" xs={12} md={6}>
            <div style={{height: '100%'}} className="project-hov rounded shadow d-flex flex-column">
                <div className="w-100">
                    {
                        (id !== gameProject) ?
                        <a href={client_side} title="Click to see code">
                            <img className="span-img w-100 rounded" src={img_i} alt="" />
                        </a> :
                        <a href={client_side} title="Click to see code">
                            <img style={{maxHeight: '242px'}} className="span-img w-100 rounded" src={img_i} alt="" />
                        </a>
                    }
                </div>
                <div className="mt-3 project-link d-flex justify-content-center">
                    {
                        (id !== gameProject) &&
                        <div>
                            <a className="mx-2" target="_blank" href={live_site}>Live</a>
                            <span>|</span>
                        </div>
                    }

                    {
                        ((id === gameProject) || !server_side) &&
                        <a  className="mx-2" target="_blank" href={client_side}>Code</a>
                    }
                    
                    {
                        (id !== gameProject) && server_side &&
                        <div>
                            <a className="mx-2" target="_blank" href={client_side}>Frontend Code</a>
                            <span>|</span>
                        </div>

                    }
                    
                    {
                        server_side &&
                        <a className="mx-2" target="_blank" href={server_side}>Backend Code</a>
                    }
                </div>
                <h6 className="mt-4 mb-3 text-center"><strong>{title}</strong></h6>
                <div className="mb-4 pb-1 ms-2 me-3 d-flex justify-content-center">
                    <ul>
                        {
                            short_desc.map(elem => {
                                return <DescList key={elem} elem={elem}/>
                            })
                        }
                    </ul>
                </div>
                <div style={{height: '100%'}} className="mb-5 text-center d-flex justify-content-center align-items-end">
                    <Link to={`/details/${title.toLowerCase().split(' ').join('').trim()}`}><button className="btn-details py-3 px-2 rounded-circle"><small><em>Details</em></small></button></Link>
                </div>
            </div>
        </Col>
    )
}

export default TopProject;